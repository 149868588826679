import React from 'react';
import headerr from "../assets/headerr.jfif";

const Header = () => {
    return (
        <div>
            <div className="container-fluid bodyy  p-0">
                <div className="row ">
                    <div className="col-xl-12 morst">
                        <img className="headeri p-0" src={headerr} alt=""/>

                        <div  data-aos="zoom-in-down" className="header-text">
                            <h1 className="header-title">
                                <span className="colorr">МЫ ПОСТРОИЛИ</span>
                                БОЛЬШИЕ ПРОЕКТЫ</h1>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Header;