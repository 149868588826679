import React from 'react';
import './about.css';
import sdddd from '../assets/sdddd.jpg';
import Footer from "./Footer";
import polyt from '../assets/polyt.jpeg'
import Header from "./Header";
import Butoo from "../component/Butoo";

const About = () => {
    return (
        <div>
            <Header/>
            <Butoo/>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-xl-6 w-100 ">
                       <div className="card mt-5">
                           <div className="card-body actt ">
                               <img className="img-abt"  src={sdddd} alt=""/>
                               <div className="scrl">
                                   <h4 className="about-text">
                                       Наша группа начала строительный сектор с мраморных работ в 1989 году и успешно завершила мраморные работы 500 000 домов.С 1991 года она занимается производством, продажей и арендой строительных лесов, стальной опалубки, телескопического монтажа, а также сборных строительных и контейнерных элементов. 1999.

                                       Ведущий турецкий производитель строительных лесов и опалубки обслуживает компанию с 400 000 м² строительных лесов, 400 000 телескопических опор, 200 000 м³ опалубки и опытным техническим персоналом.
                                       Системы строительных лесов производства нашей компании; Надежные фланцевые системы лесов, безопасные системы лесов типа Н, строительные леса несущей башни, передвижные леса, подвесные леса, системы трибун, леса с чашечным замком, строительные леса столового типа.

                                       Опалубочные системы на нашем производстве: изготовление тоннельной опалубки, изготовление всех видов стальной опалубки, подъемно-переставные опалубочные системы, подъемно-переставные опалубочные системы, опалубочные системы из фанеры, опалубочные системы с опускающейся головкой, регулируемые опалубки для колонн.

                                       Наша группа обслуживает не только строительную отрасль Турции. Кроме того, он был организован за рубежом и организован в 10 различных населенных пунктах в таких странах, как Россия, Казахстан, Татарстан, Киргизия, Украина, Румыния, Москва, Алмата, Астана, Атрау, Бишкек и др. С 2000 года компания обслуживает строительный сектор в этих странах, занимаясь арендой и продажей, имея около 150 опытных сотрудников.

                                       Имея 100 опытных сотрудников в Турции, компания оказывает все виды услуг по производству, аренде и продаже в своих филиалах в Анкаре, а также в Стамбуле, а также обслуживает строительный сектор с открытой и закрытой площадью 32 000 м².
                                   </h4>
                               </div>





                           </div>

                           <div className="card-body actt mt-5"><div className="mt-5">
                               <h4 className="about-text">
                                   <span className="d-block">Политика качества</span>
                                   Цель существования нашей компании: Приносить ощутимую пользу нашим клиентам, нашему обществу и нашим сотрудникам. Разрабатывать и использовать конкурентоспособные методы ведения бизнеса, сочетая прошлые знания и опыт с сегодняшними ценностями. Внести свой вклад в турецкую экономику и занятость.

                                   Обеспечить и повысить удовлетворенность клиентов качественной продукцией, технической поддержкой и послепродажным обслуживанием.
                                   Поощрять командную работу, обеспечивая участие каждого в нашем непрерывном развитии с сознательным участием наших сотрудников с высокой мотивацией и решимостью добиться успеха.
                                   Чтобы превысить свои цели, обеспечивая постоянное развитие и прогресс во всех процессах, которые влияют на успех нашей компании и качество нашей продукции.
                                   Добавление новых ценностей к каждой проделанной работе.
                                   Быть компанией с высокой конкурентоспособностью в своем секторе, с верой в то, что качество - это процесс, который требует постоянного развития и преемственности, а не цель, которую нужно достичь.
                               </h4>
                           </div>
                               <img className="img-abt mt-5" src={polyt} alt=""/>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default About;