import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Butoo from "../component/Butoo";
import produc from '../assets/produc.jpg'
import '../component/products.css';
import lesa from '../assets/lesa.png';
import lessa1 from '../assets/lessa1.jfif';
import lesa2 from '../assets/lesa2.jpg'


const Products = () => {
    return (
        <div>
            <Header/>
            <Butoo/>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body ">

                                <div className="">
                                    <img className="produc" src={produc} alt=""/>
                                </div>

                                <div className="text-produc ">
                                    <h2 className="headtext">ЗАЩИТНЫЕ ЛЕСА ТИПА H</h2>
                                    <h3>TS EN 12810 - 4D - SW 06 / 250 - H1 - A / B - LA</h3>
                                    <p className="conet">Защитные леса типа H изготавливаются в размерах 150 см, 200 см
                                        и 250 см горизонтальных элементов, используемых в фасадных системах, и легко
                                        адаптируются к размерам фасадов разной длины. Система защитных лесов типа H
                                        состоит из регулировочного вала, стартовой опоры, вертикальной рамы,
                                        горизонтального соединения, боковых перил, крестовины, платформы, пятки и
                                        лестничной платформы. В системе защитных лесов типа H домкраты
                                        изготовлены из стальных промышленных труб S38JR в сечениях Ø4x48 мм, рамах
                                        Ø3,3x34 мм, горизонтях Ø2x42 мм и крестовинах Ø4,2x5,35 мм</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 mt-3">
                        <div className="card bg-warning text-white">
                            <div className="card-body">
                                <h4 className="mdf"> СИСТЕМЫ ЗАЩИТНЫХ ЛЕСОВ ЯВЛЯЮТСЯ СЕРТИФИЦИРОВАННЫМИ ПО КАЧЕСТВУ
                                    СТРОИТЕЛЬНЫМИ ЛЕСАМИ</h4>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-group">
                                <h5 className="textgrop text-center mt-3">Горизонтальные части фасадных систем H Type
                                    Scaffolding
                                    изготавливаются размером 250 см. Части строительных лесов H-типа состоят из верхней
                                    регулировочной оси,
                                    начальной горизонтальной, вертикальной рамы, U-образных предохранительных концов,
                                    диагоналей, платформ,
                                    15-сантиметровых каблуков безопасности и лестничных платформ.
                                    Все компоненты строительных лесов соответствуют стандартам TS EN 12810-1, 12810-2,
                                    TS EN 12811-1, TS EN 12811-2, TS EN 12811-3 и успешно прошли испытания на падение и
                                    транспортировку.</h5>

                                <div className="gropimg">
                                    <img className="img-margin" src={lesa} alt=""/>
                                    <img className="img-margin" src={lessa1} alt=""/>
                                    <img className="img-margin" src={lesa2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 mt-3">
                            <div className="card bg-warning">
                                <div className="card-body  text-white">
                                    <h4 className="text-center nthtext">ПОДМНОЖЕСТВО ВАЛОВ</h4>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Products;