import React from 'react';
import  './MyNav.css';
import logocom from "../assets/logocom.jpg";
import {Link} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Products from "./Products";
import Contact from "./Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const MyNav = () => {
    return (

        <div className="pos">
            <nav className="navbar navbar-expand-sm navm  mb-2 navbar-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand mx-3" to="/"><img className="lods" src={logocom} alt=""/></Link>
                    <button className="navbar-toggler med" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Главная</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">О компании</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/products">Каталог продукции</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/projects">Наши проекты</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Контакты</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    );
};

export default MyNav;