import React, {useEffect} from "react";
import './Home.css';
import headerr from "../assets/headerr.jfif";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import bodyim from "../assets/bodyim.jpg";
import 'aos/dist/aos.css';
import AOS from 'aos';
import FORMWORKS from "../assets/FORMWORKS.jpg";
import Footer from "./Footer";
import end from '../assets/end.png';
import uslug from '../assets/uslug.png';
import Butoo from '../component/Butoo';
import Header from "./Header";

const Home = () => {
    useEffect(() => {
        AOS.init({
            duration:2000
        });
    },[]);
    return (
        <div>

            <Header/>
            <h1 className="text-center mt-5">Нащи услуги</h1>

            <div className="container-fluid my-5 ">
                <div className="row">
                    <div className="col-xl-4">

                        <div className="card" data-aos="fade-up"
                             data-aos-anchor-placement="top-bottom">
                            <div className="card-body headd d-block">
                                <img className="bodyim " src={bodyim} alt=""/>
                                <h5>ПРОИЗВОДСТВО И АРЕНДА ЛЕСА</h5>
                                <p>Разнообразие строительных материалов на сегодняшний день
                                    достаточно широкое, однако на протяжении длительного
                                    периода необходимыми и востребованными остаются строительные леса.
                                    Они обладают характеристиками и свойствами, благодаря которым обеспечивается
                                    продолжительный эксплуатационный период с их сохранением в первоначальном виде.
                                    Особенно актуально их использовать в процессе отделки фасадов,
                                    при ремонтных и строительно-монтажных работах.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4" >
                        <div className="card" data-aos="fade-up"
                             data-aos-anchor-placement="top-bottom">
                            <div className="card-body headd d-block"style={{boxSizing: "border-box"}}>
                                <img className="bodyim" src={FORMWORKS} alt=""/>
                                <h5>ПРОИЗВОДСТВО И АРЕНДА ОПАЛУБКИ</h5>
                                <p>Современное строительство зачастую отличается
                                    сложной архитектурой зданий и наличием особых требований к постройкам и срокам их выполнения. Как правило, в таких проектах требуется специальное оборудование, способно упростить процесс и сделать его более быстрым. Сегодня с развитием монолитного строительства и современной опалубки такие проблемы, как строительство сложных сооружений
                                    в краткие сроки, стали легко выполнимыми. Опалубка - это инструмент, который придает форму будущему зданию или сооружению. </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4">
                        <div className="card" data-aos="fade-up"
                             data-aos-anchor-placement="top-bottom">
                            <div className="card-body headd d-block">
                                <img className="bodyim" src={bodyim} alt=""/>
                                <h5>ПРОИЗВОДСТВО И АРЕНДА ЛЕСА</h5>
                                <p>Разнообразие строительных материалов на сегодняшний день
                                    достаточно широкое, однако на протяжении длительного
                                    периода необходимыми и востребованными остаются строительные леса.
                                    Они обладают характеристиками и свойствами, благодаря которым обеспечивается
                                    продолжительный эксплуатационный период с их сохранением в первоначальном виде.
                                    Особенно актуально их использовать в процессе отделки фасадов,
                                    при ремонтных и строительно-монтажных работах.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" container-fluid my-5"
                 data-aos="fade-down"
                 data-aos-easing="linear"
                 data-aos-duration="1500">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body end-body ">
                                <div className="col-xl-6">
                                    <h1 className="eng-text">КАЧЕСТВЕННЫЕ СТРОИТЕЛЬНЫЕ ЛЕСА, ЗАРЕГИСТРИРОВАННЫЕ СЕРТИФИКАТАМИ КАЧЕСТВА</h1>
                                    <h5 className="uslug"><img src={uslug} alt=""/>Аренда Охрана Наружные стеновые строительные леса</h5>
                                    <h5 className="uslug"><img src={uslug} alt=""/>Аренда фланцевых строительных лесов</h5>
                                    <h5 className="uslug"><img src={uslug} alt=""/>Безопасные строительные леса для продажи</h5>
                                    <h5 className="uslug"><img src={uslug} alt=""/>Простая установка и долговечные продукты</h5>


                                </div>
                                <div className="col-xl-6">
                                    <img className="end-img" src={end} alt=""/>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Butoo/>

            <Footer/>

        </div>
    );
};

export default Home;

