import React, {Component} from 'react';
import {ToastContainer} from 'react-toastify';
import {Route, Switch} from "react-router-dom";
import Home from "./component/Home";
import Contact from "./component/Contact";
import About from "./component/About";
import Products from "./component/Products";
import MyNav from "./component/MyNav";
import Projects from "./component/Projects";


class App extends Component {

    render() {

        return (
            <div className="container-fluid ">
                <MyNav/>

                <Switch>
                    <Route exact path={'/'}  component={Home}/>
                    <Route exact path={'/contact'}  component={Contact}/>
                    <Route exact path={'/about'}  component={About}/>
                    <Route exact path={'/products'}  component={Products}/>
                    <Route exact path={'/projects'}  component={Projects}/>
                </Switch>


                <ToastContainer/>
            </div>
        );
    }
}

export default App;