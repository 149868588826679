import React from 'react';
import './footr.css';
import logocom from "../assets/logocom.jpg";
import contact from '../assets/contact.png';
import insst from '../assets/insst.png';
import teleg from '../assets/teleg.jpg';
import gmail from '../assets/gmail.png';
import Butoo from "../component/Butoo";

const Footer = () => {
    return (
        <div>
            <Butoo/>
            <div className="container-fluid w-100 mb-3 p-0">
                <div className="row global-footer">
                    <div className="col-xl-12">
                        <div className="footer">
                            <div className="">
                                <img className="footer-img" src={logocom} alt=""/>
                                <a href="#" className="text-decoration-none"><h1 className="footer-text">Строительные леса Eyüboğlu <span className="text-warning">|</span> </h1></a>
                                <a href="#" className="text-decoration-none rejm"><h3 className="footer-text-1">РЕЖИМ РАБОТЫ:</h3></a>
                                <a href="#" className="text-decoration-none"><h4 className="footer-text-2">ПН - СБ (9:00 - 18:00)</h4></a>
                            </div>
                            
                            <div className="footer-2">
                                <a href="#" className="con-fot  text-decoration-none"><img src={contact} alt=""/> Свяжитесь с нами:</a>
                                <a href="#" className="fotr-inst text-decoration-none text-white"><img className="inst" src={insst} alt=""/>Instagram</a>
                                <a href="https://t.me/arendaKonteyner" className="fotr-teleg text-white text-decoration-none"><img className="teleg " src={teleg} alt=""/>Telegram</a>
                                <a href="#" className="fotr-gmail text-white text-decoration-none"><img className="gmail " src={gmail} alt=""/>Email</a>
                            </div>


                            <div className="adres">
                                <a href="#" className="text-decoration-none d-block text-white area">
                                    АДРЕС:
                                </a>

                                <a href="#" className="text-decoration-none text-white d-block">Г. ТАШКЕНТ, МИРЗО-УЛУГБЕКСКИЙ Р-Н,
                                    УЛ. УЗБЕКИСТОН ОВОЗИ, Д. 21</a>

                                <a href="#" className="text-decoration-none text-white phone">
                                    ТЕЛЕФОН ДЛЯ КОНСУЛЬТАЦИИ:
                                </a>

                                <a href="#" className="phone-2 text-decoration-none text-white mt-1">
                                    <span className="d-block">
                                       <a className="text-decoration-none text-white mt-1" href="#"> +998 99 030-25-13</a>
                                    </span>
                                    <a className="text-decoration-none text-white" href="#">+998 91 133-46-80</a>
                                </a>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default Footer;