import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import '../component/contact.css';
import Footer from "./Footer";

const Contact = () => {
    const mapState = { center: [55.76, 37.64], zoom: 10 };
    return (
<div>
    
    <div className="container-fluid p-0">
        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body">
                        <h1 >
                           <EngineeringIcon/> Связите с нами
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <div className="mt-5 card">
       <div className="card-body d-flex">
       <iframe className="con-map maper"
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.1379459657505!2d69.28886525082748!3d41.31203397916941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef4d4575a7cf7%3A0x795a2ede53d139a1!2zMjEg0YPQu9C40YbQsCDQo9C30LHQtdC60LjRgdGC0L7QvSDQntCy0L7Qt9C4LCDQotCw0YjQutC10L3RgiAxMDAwMDEsINCj0LfQsdC10LrQuNGB0YLQsNC9!5e1!3m2!1sru!2s!4v1681125145167!5m2!1sru!2s"
               width="" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy"
               referrerPolicy="no-referrer-when-downgrade">Map</iframe>

       <div className="textcon">
           <h1>
               ТЕЛЕФОН ДЛЯ КОНСУЛЬТАЦИИ:
           </h1>

           <a className="text-decoration-none d-block text-dark num " href="tel:+998 99 030-25-13">
               +998 99 030-25-13
           </a>

           <a className="text-decoration-none text-dark num" href="tel: +998 91 133-46-80">
               +998 91 133-46-80
           </a>

           <div className="mt-5">
               <h6>АДРЕС</h6>

               <h4>Г. ТАШКЕНТ, МИРЗО-УЛУГБЕКСКИЙ Р-Н,
                   УЛ. УЗБЕКИСТОН ОВОЗИ, Д. 21</h4>
           </div>

           <button className="conbut btn btn-primary d-block"> <a className="text-decoration-none text-white" href="https://t.me/arendaKonteyner">Связите с нами</a></button>

       </div>
   </div>

   </div>

    <Footer/>
</div>


    );
};

export default Contact;