import ReactDOM from 'react-dom/client'
import React from "react";
import './style.css'
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
import {BrowserRouter as Router} from "react-router-dom";


const root = ReactDOM.createRoot(
    document.getElementById('root')
);
root.render(
    <Router>
        <App/>
    </Router>
);